import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"
import pluralize from "pluralize"

export const getPriceStr = (price) => {
  let priceStr = '';
  if (price.min && price.max) {
    priceStr = " between " + `AED ` + addCommas(price.min) + " and " + `AED ` + addCommas(price.max);
  } else if (price.max) {
    priceStr = " under " + `AED ` + addCommas(price.max);
  } else if (price.min) {
    priceStr = " over " + `AED ` + addCommas(price.min);
  }
  return priceStr;
}

export const getBedStr = (bedroom) => {
  let bedStr = '';
  if (bedroom.min && bedroom.max) {
    bedStr = " from " + bedroom.min + " to " + bedroom.max + " bedrooms"
  } else if (bedroom.max) {
    //bedStr = " to "+bedroom.max+" bedrooms"
    bedStr = " with less than " + bedroom.max + " bedrooms"
  } else if (bedroom.min) {
    //bedStr = " from "+bedroom.min+" bedrooms"
    bedStr = " with more than " + bedroom.min + " bedrooms"
  }
  return bedStr;
}

export const getSizeStr = (size) => {
  let sizeStr = '';
  if (size.min && size.max) {
    sizeStr = " starting from " + addCommas(size.min) + " to " + addCommas(size.max) + " sqft"
  } else if (size.max) {
    //bedStr = " to "+bedroom.max+" bedrooms"
    sizeStr = " below " + addCommas(size.max) + " sqft"
  } else if (size.min) {
    //bedStr = " from "+bedroom.min+" bedrooms"
    sizeStr = " above " + addCommas(size.min) + " sqft"
  }
  return sizeStr;
}

export const roundDecimal = (val) => {
  const commaRemoved = val?.toString().replaceAll(",", "")
  if (isNaN(commaRemoved)) return commaRemoved
  return parseInt(commaRemoved, 10)
}

function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split('.');
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
    // add comma every 3 digits before decimal
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

export const ACTION_TYPES = {
  residential_sales: " for sale in ",
  residential_lettings: " to rent in ",
  short_lettings_lettings: " for Short term rentals in ",
}

export const ACTION_DESC_TYPES = {
  residential_sales: " for sale ",
  residential_lettings: " to rent ",
  short_lettings_lettings: " holiday lets ",
}

export const pTypeBase = {
  residential_sales: "/property/for-sale/",
  residential_lettings: "/property/to-rent/",
  short_lettings_lettings: "/holiday-lets/",
}
export const pTypeBaseoff = {
  residential_sales: "/off-plan-property/for-sale/"
}
export const pTypeBaseoffplan = {
  residential_sales: "/off-plan-projects/for-sale/"
}
export const ACTION_DESC_TYPES_INTRO = {
  sales: " for sale in ",
  lettings: " to rent in ",
  short_lettings_lettings: " to rent in ",
}


export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if (_text) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if (_text.length > 0) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}
export const toEachTitleCase = (str) => {

  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location, pcategorytype, department, offplanproperty) => {
  // console.log("$$$$$$", pcategorytype, pType);
  var replace_url = pcategorytype + "_" + pType;
  var replace_url = replace_url.replace("-","_")
  // console.log("$$$$$$", replace_url);
  if (location) {
    var query = '';
    let pathUri_main = offplanproperty ? location.split(pTypeBaseoff[replace_url].slice(0, -1)) :  department === "off_plan" ? location.split(pTypeBaseoffplan[replace_url].slice(0, -1)) : location.split(pTypeBase[replace_url].slice(0, -1))
    var areaVal = ''
    var bedVal = ''
    var bathVal
    var minpriceVal = ''
    var maxpriceVal = ''
    var minbedVal = ''
    var maxbedVal = ''
    var minsizeVal = ''
    var maxsizeVal = ''
    var typeVal = ''
    var parkingVal = ''
    var rentalperiod = ''
    var mustIncludeVal = ''

    //console.log("$$$$$---url---####--", location+"----"+pathUri_main+"----"+pTypeBase+"-----"+pType+"----"+pcategorytype);

    // console.log("price__ 0",location, pType, pTypeBase[pType], pcategorytype, pathUri_main);

    //console.log("pathUri_main", pathUri_main)

    if (pathUri_main[1]) { // following could be regexp
      let pathUri = pathUri_main[1].split('/');

      //console.log("price__ 1", pathUri.length);

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") { continue; }
        // for-sale
        if (pathUri[vi].indexOf("for-sale") >= 0) {
          // its area
          pType = 'sales';
        }
        if (pathUri[vi].indexOf("to-rent") >= 0) {
          // its area
          pType = 'lettings';
        }
        if (pathUri[vi].indexOf("in-") == 0) {
          // its area
          areaVal = pathUri[vi].replace("in-", "").replace("-", " ");
        }
        if (pathUri[vi].indexOf("-and-more-bedrooms") >= 0) {
          // its bedrooms
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }
        if (pathUri[vi].indexOf("parking-") >= 0) {
          // its bedrooms
          parkingVal = pathUri[vi].replace("parking-", "")
        }
        if (pathUri[vi].indexOf("rental-period-") >= 0) {
          // its bedrooms
          rentalperiod = pathUri[vi].replace("rental-period-", "")
        }
        if (pathUri[vi].indexOf("-and-more-bathrooms") >= 0) {
          // its bedrooms
          bathVal = pathUri[vi].replace("-and-more-bathrooms", "")
        }
        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        //console.log("price__", pathUri[vi].indexOf("for-sale"));

        if (pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          // its price
          let priceFilt1 = pathUri[vi].split('over-');
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split('under-');
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split('between-');
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split('-and-');
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        if (pathUri[vi].indexOf("size-") >= 0 ||
          pathUri[vi].indexOf("sizestarting-") >= 0 ||
          pathUri[vi].indexOf("sizetill-") >= 0
        ) {
          // its size
          let priceFilt1 = pathUri[vi].split('sizestarting-');
          if (priceFilt1[1]) {
            minsizeVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split('sizetill-');
          if (priceFilt2[1]) {
            maxsizeVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split('size-');
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split('-both-');
            minsizeVal = priceFilt4[0]
            maxsizeVal = priceFilt4[1]
          }
        }

        // Bedrooms Range
        if (
          pathUri[vi].indexOf("from-") >= 0 ||
          pathUri[vi].indexOf("to-") >= 0 ||
          pathUri[vi].indexOf("bedrooms-to-") >= 0
        ) {
          let bedroomFilt1 = pathUri[vi].split("from-")
          if (bedroomFilt1[1]) {
            var splitbed1 = bedroomFilt1[1].split("-bedrooms")
            if (bedroomFilt1[1].indexOf("to-") <= 0) {
              minbedVal = splitbed1[0]
            }
          }
          let bedroomFilt2 = pathUri[vi].split("to-")
          if (bedroomFilt2[0] == "" && bedroomFilt2[1]) {
            var splitbed2 = bedroomFilt2[1].split("-bedrooms")
            maxbedVal = splitbed2[0]
          }
          let bedroomFilt3 = pathUri[vi].split("bedrooms-")
          if (bedroomFilt3[0] && bedroomFilt3[1]) {
            if (bedroomFilt3[0].indexOf("from-") >= 0) {
              var splitbed3 = bedroomFilt3[0].split("from-")
              minbedVal = splitbed3[1].replace(/-/g, "")
            }
            if (bedroomFilt3[1].indexOf("to-") >= 0) {
              var splitbed4 = bedroomFilt3[1].split("to-")
              maxbedVal = splitbed4[1].replace(/-bedrooms/g, "")
            }


          }
        }
        // Bedrooms Range

        // Must Include
        if (pathUri[vi].indexOf("with-") >= 0) {
          var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
          mustIncludeVal = mustIncludeArrfilt.split("-and-")
        }
        let priceFilt5 = pathUri[vi].split('type-');
        if (priceFilt5[1]) {
          typeVal = priceFilt5[1]
        }
      }
    }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
      areaVal = "Dubai"; // TODO
    }

    var areaAlias = areaVal.replace(' ', '-');

    //console.log("$$$$$$$---parse---", pType, typeVal, minpriceVal, maxpriceVal)

    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      bathrooms: bathVal,
      pcategorytype: pcategorytype,
      type: typeVal,
      parking: parkingVal,
      rentalperiod: rentalperiod,
      mustInclude: mustIncludeVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      bedroom: {
        min: minbedVal,
        max: maxbedVal,
      },
      size: {
        min: minsizeVal,
        max: maxsizeVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = (params) => {
  const { areas, bedrooms, bedroom, bathrooms, pType, type, price, mustInclude } = params;

  let bedroomValue = bedrooms || bedroom;
  let bathroomValue = bathrooms || bathrooms;

  let searchParams = {
    department: "residential",
    searchType: pType,
    areas: areas,
    // bedroom: bedrooms,
    // bathroom: bathrooms,
    minPrice: price.min,
    maxPrice: price.max,
    // minBedroom:  bedroom.min,
    // maxBedroom:  bedroom.max,
    building: type,
    must_Include: mustInclude,
  }

  if (typeof bedroomValue === 'string') {
    searchParams["bedroom"] = bedroomValue
  }
  if (typeof bedroomValue === 'object') {
    searchParams["minBedroom"] = bedroomValue?.min || ''
    searchParams["maxBedroom"] = bedroomValue?.max || ''
  }

  if (typeof bathroomValue === 'string') {
    searchParams["bathroom"] = bathroomValue
  }
  if (typeof bathroomValue === 'object') {
    searchParams["minBathroom"] = bathroomValue?.min || ''
    searchParams["maxBathroom"] = bathroomValue?.max || ''
  }

}


// TODO: hard coded details to be removed/replaced with common variables
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const propertyH1 = (params, pcategorytype, listType = "Properties") => {

  const { areas, bedrooms, bedroom, size, parking, rentalperiod, bathrooms, pType, type, price, extra, mustInclude } = params;
  // console.log('areas : ' + areas);
  var replace_url = pcategorytype + "_" + pType;
  replace_url = replace_url.replace("-", "_")

  let h1 = "";
  let building = listType;
  if (type) {
    building = decodeURIComponent(toTitleCase(pluralize(type)));
  }
  h1 += toTitleCase(building);
  h1 += ACTION_TYPES[replace_url];

  if (areas) {
    let _areas = areas;
    //console.log("_areas_areas", _areas.split("-and-"))
    let areaarray = _areas.split("-and-")
    var areaform = []
    for (let areasplit of areaarray) {
      //areasplit = areasplit.charAt(0).toUpperCase() + areasplit.substr(1);

      //console.log("_areas_areas", toTitleCase(areasplit));
      areaform.push(toTitleCase(areasplit))
    }
    let newOrder = ""
    areaform.forEach((item, index) => {
      //console.log("areaform.length", areaform.length)
      if (index === areaform.length - 3) {
        newOrder += toTitleCase(item) + ', and '
      }
      else if (index === areaform.length == 2) {
        newOrder += toTitleCase(item) + ' and '
      } else if (index === areaform.length - 1) {
        newOrder += toTitleCase(item)
      } else {
        newOrder += toTitleCase(item + ', ')
      }

    })
    newOrder = areaform.join(' and ');


    _areas = newOrder.replace("-", " ");
    _areas = decodeURIComponent(_areas);
    _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
    //console.log("_areas_areas", toEachTitleCase(_areas.replace(/-/g, ' ')));
    h1 += toEachTitleCase(_areas.replace(/-/g, ' '))
  }
  if (bedrooms) {
    let bedPlus = extra.bedPlus ? '+' : '+';
    h1 += ' with more than ' + bedrooms + ' bedrooms'
  }
  if (bathrooms) {
    let bedPlus = extra.bedPlus ? '+' : '+';
    h1 += ' with ' + bathrooms + ' bathrooms'
  }

  if (price && (price.min || price.max)) {
    h1 += getPriceStr(price);
  }
  if (bedroom && (bedroom.min || bedroom.max)) {
    h1 += getBedStr(bedroom);
  }
  if (size && (size.min || size.max)) {
    h1 += getSizeStr(size);
  }
  if (mustInclude && mustInclude) {
    var includecap = mustInclude.map(a => a.charAt(0).toUpperCase() + a.substr(1));
    h1 += " with " + includecap.join('-and-')
  }
  if (parking) {
    h1 += ' (parking ' + parking + '+)'
  }
  if (rentalperiod) {
    h1 += ' Rental Period (' + toTitleCase(rentalperiod) + ')'
  }

  // console.log("H1 title => ", h1);
  return h1;
}

export const offPlanPropertyH1 = (params, pcategorytype, listType) => {
  const { areas, bedrooms, bedroom, bathrooms, pType, type, price, extra } = params;

  var replace_url = pcategorytype + "_" + pType;
  replace_url = replace_url.replace("-", "_")

  let h1 = "";
  let building = listType;
  if (type) {
    building = listType + decodeURIComponent(toTitleCase(pluralize(type)));
  }
  h1 += toTitleCase(building);
  h1 += " for sale in ";

  if (areas) {
    let _areas = areas;
    //console.log("_areas_areas", _areas.split("-and-"))
    let areaarray = _areas.split("-and-")
    var areaform = []
    for (let areasplit of areaarray) {
      //areasplit = areasplit.charAt(0).toUpperCase() + areasplit.substr(1);

      //console.log("_areas_areas", toTitleCase(areasplit));
      areaform.push(toTitleCase(areasplit))
    }
    let newOrder = ""
    areaform.forEach((item, index) => {
      //console.log("areaform.length", areaform.length)
      if (index === areaform.length - 3) {
        newOrder += toTitleCase(item) + ', and '
      }
      else if (index === areaform.length == 2) {
        newOrder += toTitleCase(item) + ' and '
      } else if (index === areaform.length - 1) {
        newOrder += toTitleCase(item)
      } else {
        newOrder += toTitleCase(item + ', ')
      }

    })
    newOrder = areaform.join(' and ');


    _areas = newOrder.replace("-", " ");
    _areas = decodeURIComponent(_areas);
    _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
    //console.log("_areas_areas", toEachTitleCase(_areas.replace(/-/g, ' ')));
    h1 += toEachTitleCase(_areas.replace(/-/g, ' '))
  }
  if (bedrooms) {
    let bedPlus = extra.bedPlus ? '+' : '+';
    h1 += ' with more than ' + bedrooms + ' bedrooms'
  }
  if (bathrooms) {
    let bedPlus = extra.bedPlus ? '+' : '+';
    h1 += ' with ' + bathrooms + ' bathrooms'
  }

  if (price && (price.min || price.max)) {
    h1 += getPriceStr(price);
  }
  if (bedroom && (bedroom.min || bedroom.max)) {
    h1 += getBedStr(bedroom);
  }

  // console.log("H1 title => ", h1);
  return h1;
}

export const offPlanPropertyH1INTRO = (params, pcategorytype, listType) => {
  const { areas, bedrooms, bedroom, bathrooms, pType, type, price, extra } = params;

  var replace_url = pcategorytype + "_" + pType;
  replace_url = replace_url.replace("-", "_")

  let h1 = "";
  let building = listType;
  if (type) {
    building = decodeURIComponent(toTitleCase(pluralize(type)));
  }
  h1 += toTitleCase(building);
  h1 += " for sale in ";

  if (areas) {
    let _areas = areas;
    //console.log("_areas_areas", _areas.split("-and-"))
    let areaarray = _areas.split("-and-")
    var areaform = []
    for (let areasplit of areaarray) {
      //areasplit = areasplit.charAt(0).toUpperCase() + areasplit.substr(1);

      //console.log("_areas_areas", toTitleCase(areasplit));
      areaform.push(toTitleCase(areasplit))
    }
    let newOrder = ""
    areaform.forEach((item, index) => {
      //console.log("areaform.length", areaform.length)
      if (index === areaform.length - 3) {
        newOrder += toTitleCase(item) + ', and '
      }
      else if (index === areaform.length == 2) {
        newOrder += toTitleCase(item) + ' and '
      } else if (index === areaform.length - 1) {
        newOrder += toTitleCase(item)
      } else {
        newOrder += toTitleCase(item + ', ')
      }

    })
    newOrder = areaform.join(' and ');


    _areas = newOrder.replace("-", " ");
    _areas = decodeURIComponent(_areas);
    _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
    //console.log("_areas_areas", toEachTitleCase(_areas.replace(/-/g, ' ')));
    h1 += toEachTitleCase(_areas.replace(/-/g, ' '))
  }
  // if (bedrooms) {
  //   let bedPlus = extra.bedPlus ? '+' : '+';
  //   h1 += ' with more than ' + bedrooms + ' bedrooms'
  // }
  // if (bathrooms) {
  //   let bedPlus = extra.bedPlus ? '+' : '+';
  //   h1 += ' with ' + bathrooms + ' bathrooms'
  // }

  // if (price && (price.min || price.max)) {
  //   h1 += getPriceStr(price);
  // }
  // if (bedroom && (bedroom.min || bedroom.max)) {
  //   h1 += getBedStr(bedroom);
  // }

  // console.log("H1 title => ", h1);
  return h1;
}

export const propertyDesc = (params, pcategorytype) => {

  // console.log('params : ' + params);

  const { areas, bedrooms, bedroom, size, parking, rentalperiod, bathrooms, pType, type, price, extra, mustInclude } = params;
  // console.log('areas : ' + areas);
  var replace_url = pcategorytype + "_" + pType;

  let h1 = "";
  let building = "Properties";
  if (type) {
    building = decodeURIComponent(toTitleCase(type + "s"));
  }
  h1 += building.toLowerCase();
  h1 += ACTION_TYPES[replace_url];

  if (areas) {
    let _areas = areas;
    //console.log("_areas_areas", _areas.split("-and-"))
    let areaarray = _areas.split("-and-")
    var areaform = []
    for (let areasplit of areaarray) {
      //areasplit = areasplit.charAt(0).toUpperCase() + areasplit.substr(1);

      //console.log("_areas_areas", toTitleCase(areasplit));
      areaform.push(toTitleCase(areasplit))
    }
    let newOrder = ""
    areaform.forEach((item, index) => {
      //console.log("areaform.length", areaform.length)
      if (index === areaform.length - 3) {
        newOrder += toTitleCase(item) + ', and '
      }
      else if (index === areaform.length == 2) {
        newOrder += toTitleCase(item) + ' and '
      } else if (index === areaform.length - 1) {
        newOrder += toTitleCase(item)
      } else {
        newOrder += toTitleCase(item + ', ')
      }

    })
    newOrder = areaform.join(' and ');


    _areas = newOrder.replace("-", " ");
    _areas = decodeURIComponent(_areas);
    _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
    //console.log("_areas_areas", toEachTitleCase(_areas.replace(/-/g, ' ')));
    h1 += toEachTitleCase(_areas.replace(/-/g, ' '))
  }
  if (bedrooms) {
    let bedPlus = extra.bedPlus ? '+' : '+';
    h1 += ' with more than ' + bedrooms + ' bedrooms'
  }
  if (bathrooms) {
    let bedPlus = extra.bedPlus ? '+' : '+';
    h1 += ' with ' + bathrooms + ' bathrooms'
  }

  if (price && (price.min || price.max)) {
    h1 += getPriceStr(price);
  }
  if (bedroom && (bedroom.min || bedroom.max)) {
    h1 += getBedStr(bedroom);
  }
  if (size && (size.min || size.max)) {
    h1 += getSizeStr(size);
  }
  if (mustInclude && mustInclude) {
    var includecap = mustInclude.map(a => a.charAt(0).toUpperCase() + a.substr(1));
    h1 += " with " + includecap.join('-and-')
  }
  if (parking) {
    h1 += ' (parking ' + parking + '+)'
  }
  if (rentalperiod) {
    h1 += ' Rental Period (' + toTitleCase(rentalperiod) + ')'
  }

  // console.log("H1 title => ", h1);
  return h1;
}

export const propertyDescIntroCopy = (params, pcategorytype) => {

  // console.log('params : ' + params);

  const { areas, bedrooms, bedroom, size, parking, rentalperiod, bathrooms, pType, type, price, extra, mustInclude } = params;
  // console.log('areas : ' + areas);
  var replace_url = pcategorytype + "_" + pType;

  let h1 = "";
  // let building = "Properties";
  // if (type) {
  //   building = decodeURIComponent(toTitleCase(type + "s"));
  // }
  // h1 += building.toLowerCase();
  h1 += ACTION_TYPES[replace_url];

  if (areas) {
    let _areas = areas;
    //console.log("_areas_areas", _areas.split("-and-"))
    let areaarray = _areas.split("-and-")
    var areaform = []
    for (let areasplit of areaarray) {
      //areasplit = areasplit.charAt(0).toUpperCase() + areasplit.substr(1);

      //console.log("_areas_areas", toTitleCase(areasplit));
      areaform.push(toTitleCase(areasplit))
    }
    let newOrder = ""
    areaform.forEach((item, index) => {
      //console.log("areaform.length", areaform.length)
      if (index === areaform.length - 3) {
        newOrder += toTitleCase(item) + ', and '
      }
      else if (index === areaform.length == 2) {
        newOrder += toTitleCase(item) + ' and '
      } else if (index === areaform.length - 1) {
        newOrder += toTitleCase(item)
      } else {
        newOrder += toTitleCase(item + ', ')
      }

    })
    newOrder = areaform.join(' and ');


    _areas = newOrder.replace("-", " ");
    _areas = decodeURIComponent(_areas);
    _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
    //console.log("_areas_areas", toEachTitleCase(_areas.replace(/-/g, ' ')));
    h1 += toEachTitleCase(_areas.replace(/-/g, ' '))
  }
  if (bedrooms) {
    let bedPlus = extra.bedPlus ? '+' : '+';
    h1 += ' with more than ' + bedrooms + ' bedrooms'
  }
  // if (bathrooms) {
  //   let bedPlus = extra.bedPlus ? '+' : '+';
  //   h1 += ' with ' + bathrooms + ' bathrooms'
  // }

  // if (price && (price.min || price.max)) {
  //   h1 += getPriceStr(price);
  // }
  // if (bedroom && (bedroom.min || bedroom.max)) {
  //   h1 += getBedStr(bedroom);
  // }
  // if (size && (size.min || size.max)) {
  //   h1 += getSizeStr(size);
  // }
  // if (mustInclude && mustInclude) {
  //   var includecap = mustInclude.map(a => a.charAt(0).toUpperCase() + a.substr(1));
  //   h1 += " with " + includecap.join('-and-')
  // }
  // if (parking) {
  //   h1 += ' (parking ' + parking + '+)'
  // }
  // if (rentalperiod) {
  //   h1 += ' Rental Period (' + toTitleCase(rentalperiod) + ')'
  // }

  // console.log("H1 title => ", h1);
  return h1;
}